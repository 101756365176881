<template>
  <div>
    
    <v-card elevation="0">
      <validation-observer ref="observer">
        <v-form class="mt-4">
          <v-container class="pt-5">
            <v-row>
              <v-col md="3" sm="12" cols="12">
                <validation-provider v-slot="{ errors }" vid="inicio">
                  <v-text-field
                    v-model="contratoNovo.inicio"
                    outlined
                    dense
                    v-mask="'##/##/####'"
                    :readonly="readonly"
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    label="Início contrato"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col md="3" sm="12" cols="12">
                <validation-provider v-slot="{ errors }" vid="termino">
                  <v-text-field
                    v-model="contratoNovo.termino"
                    outlined
                    dense
                    v-mask="'##/##/####'"
                    :readonly="readonly"
                    hide-details="auto"
                    :disabled="$store.state.layout.loading"
                    label="Término contrato"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col md="6" sm="12" cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  vid="contratoNovo.area_id"
                >
                  <autocomplete-areas
                    v-model="contratoNovo.area_id"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="2" sm="6" cols="6">
                <validation-provider v-slot="{ errors }" vid="valor">
                  <v-text-field-money
                    v-model="contratoNovo.valor"
                    label="Valor contrato"
                    :disabled="$store.state.layout.loading"
                    hide-details="auto"
                    :properties="{
                      outlined: true,
                      dense: true,
                      prefix: 'R$',
                      readonly: false,
                      clearable: true,
                      placeholder: ' ',
                    }"
                    :options="{
                      locale: 'pt-BR',
                      length: 9,
                      precision: 2,
                      empty: null,
                    }"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col md="2" sm="6" cols="6">
                <validation-provider v-slot="{ errors }" vid="vagas">
                  <v-text-field-integer
                    v-model="contratoNovo.vagas"
                    label="Qtde. Vagas"
                    :disabled="$store.state.layout.loading"
                    hide-details="auto"
                    :properties="{
                      outlined: true,
                      dense: true,
                      readonly: false,
                      clearable: true,
                      placeholder: ' ',
                    }"
                    :options="{
                      inputMask: '###',
                      outputMask: '###',
                      empty: null,
                    }"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col class="mt-2">
                <v-switch
                  v-model="contratoNovo.ativo"
                  class="mt-0 pt-0"
                  :readonly="
                    $store.getters['usuario/temPermissao'](
                      'mostrar.contratos'
                    ) &&
                      !$store.getters['usuario/temPermissao'](
                        'editar.contratos'
                      )
                  "
                  :disabled="$store.state.layout.loading"
                  label="Ativo"
                />
              </v-col>
              <v-col class="mt-2">
                <v-switch
                  v-model="contratoNovo.isento"
                  class="mt-0 pt-0"
                  :readonly="
                    $store.getters['usuario/temPermissao'](
                      'mostrar.contratos'
                    ) &&
                      !$store.getters['usuario/temPermissao'](
                        'editar.contratos'
                      )
                  "
                  :disabled="$store.state.layout.loading"
                  label="Isento"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" sm="12" cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  vid="contratoNovo.tipo_pagamento_id"
                >
                  <autocomplete-tipo-pagamento
                    v-model="contratoNovo.tipo_pagamento_id"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col md="4" sm="12" cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  vid="contratoNovo.tipo_cobranca"
                >
                  <autocomplete-forma-cobranca
                    v-model="contratoNovo.tipo_cobranca"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col md="2" sm="12" cols="12">
                <validation-provider v-slot="{ errors }" vid="dia_vencimento">
                  <v-text-field-integer
                    v-model="contratoNovo.dia_vencimento"
                    label="Dia Vencimento"
                    :disabled="$store.state.layout.loading"
                    hide-details="auto"
                    :properties="{
                      outlined: true,
                      dense: true,
                      readonly: false,
                      clearable: true,
                      placeholder: ' ',
                    }"
                    :options="{
                      inputMask: '##',
                      outputMask: '##',
                      empty: null,
                    }"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </validation-observer>
      <v-divider class="mt-4" />
      <v-card-actions>
        <v-row>
          <v-col cols="12" class="text-left">
            <btn-salvar
              :temPermissao="
                $store.getters['usuario/temPermissao']('editar.contratos')
              "
              :carregando="carregandoSalvar"
              @click="$emit('salvar', contratoNovo)"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import formatarData from "@/utils/formatar-data-input";
export default {
  props: {
    validacaoFormulario: {
      type: Object,
      default: () => {
        return {};
      },
    },
    carregandoSalvar: {
      type: Boolean,
      default: false,
    },
   
    readonly: {
      type: Boolean,
      default: false,
    },
    contrato: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      contratoNovo: {
        id: null,
        inicio: null,
        termino: null,
        area_id: null,
        cliente_id: null,
        dia_vencimento: null,
        valor: null,
        vagas: null,
        ativo: true,
        isento: false,
        tipo_pagamento_id: null,
        tipo_cobranca: null,
      },
      areaId: null,
      tipoPagamentoId: null,
      formaCobrancaId: null,
    };
  },

  watch: {
    validacaoFormulario(val) {
      if (!val) return;

      return this.$refs.observer.setErrors(val);
    },

    contrato(contrato) {
      contrato.inicio = formatarData.ptBr(contrato.inicio);
      contrato.termino = formatarData.ptBr(contrato.termino);
      this.contratoNovo = contrato;
    },
  },

  methods: {},
};
</script>
